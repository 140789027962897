import {
  BATCH_ADD_FILE,
  BATCH_ADD_MODEL_FILE,
  BATCH_CHECK_RELOAD,
  BATCH_CHECK_RELOAD_COMPLETE,
  BATCH_CREATE,
  BATCH_CREATE_COMPLETE,
  BATCH_CREATE_ERROR, BATCH_HASH_FILE_START,
  BATCH_REMOVE_FILE,
  BATCH_REMOVE_MODEL_FILE,
  BATCH_REPEAT,
  BATCH_RESET,
  BATCH_START_UPLOAD,
  BATCH_UPDATE,
  BATCH_UPLOAD_CHUNK,
  BATCH_UPLOAD_CHUNK_COMPLETE,
  BATCH_UPLOAD_CHUNK_ERROR,
  BATCH_UPLOAD_COMPLETE,
  BATCH_UPLOAD_FILE_START,
  BATCHES_LOAD_COMPLETE,
  BATCHES_LOAD_ERROR,
  BATCHES_LOAD_REQUESTED
} from "../actionTypes";
import {getExtension, getSequence} from "../parseUtil";
import {cloneDeep} from "lodash";


const initialHistory = {
  inProgress:[],
  completed:[],
  loading:false,
  loaded: false,
  creating: false,
  createError: false,
  checkingReload: false,

  data:{
    isRepeat: false,
    originalBatchId: undefined,
    id:1,
    showAdvanced:false,
    sampleRate:'24000',
    description:'',
    fileName:'',
    fileType:'BIN',
    numChannels:'16',
    frameSizeLow:'128',
    frameSizeHi:'16384',
    adaptiveRateLow:'1',
    adaptiveRateHi:'3',
    adaptiveRateStep:'1',
    modelStart:'0.0',
    modelEnd:'0.0',
    processStart:'0.0',
    processEnd:'0.0',
    gateLevelGF:'95',
    midModelLengthGF:'2.0',
    utteranceLength:'50',
    gateLevelD:'50',
    midModelLengthD:'5.0 ',
    lowPass:'4000',
    highPass:'150',
    arrayWidth:'40',

  },
  files:[],
  modelFiles:[],
  uploadFileList:[],
  uploading: false,
  uploaded: false,
  uploadCount: 0,
  completedCount:0,
  uploadCompleting: false,
  uploadCompleted: false

}

const getFiles = (type, batchFileList) => {
  const fArray = [];
  if (batchFileList !== undefined) {
    for (let f of batchFileList) {
      if (f.uploadType === type) fArray.push(f);
    }
  }
  return fArray;
}

export const batch = (state = JSON.parse(JSON.stringify(initialHistory)), action = {}) => {
  switch (action.type) {

    case BATCH_REPEAT:{
      const batch = cloneDeep(action.value);
      batch.originalBatchId = batch.id;
      batch.id = undefined;
      batch.isRepeat = true;

      const sourceFiles = getFiles('SOURCE', batch.batchFileList);
      const modelFiles = getFiles('MODEL', batch.batchFileList);

      return {
        ...state,
        data:{
          ...batch
        },
        files: sourceFiles,
        modelFiles: modelFiles,
        error:true,
        loaded: false,
        loading:false,
      }
    }

    case BATCHES_LOAD_ERROR:{
      return {
        ...state,
        inProgress: [],
        completed: [],
        error:true,
        loaded: false,
        loading:false,
      }
    }

    case BATCH_UPDATE:{
      return {
        ...state,
        data:{
          ...state.data,
          ...action.value
        }
      }
    }

    case BATCHES_LOAD_REQUESTED:{
      return {
        ...state,
        error:false,
        loaded: false,
        loading:true
      }
    }


    case BATCHES_LOAD_COMPLETE:{
      const inProgress = [];
      const completed = [];

      for (let batch of action.value){

        switch (batch.status){
          case 'COMPLETE':
          case 'UPLOAD_ERROR':
          case 'ERROR':
            completed.push(batch);
            break;
          default:
            inProgress.push(batch);
        }
      }


      return {
        ...state,
        error:false,
        inProgress: inProgress,
        completed: completed,
        loaded: true,
        loading:false,
        reloading:false,
        reload:false
      }
    }

    case BATCH_ADD_FILE:{
      const file = {
        model:false,
        name: action.value.name,
        status:'QUEUED',
        bytesUploaded:0,
        md5:'',
        fileObject: action.value
      }

      let newFileList = state.files;
      newFileList.push(file);
      const extension = getExtension(state.data.fileType);
      newFileList = newFileList.sort((a, b)=>{
        const sa = getSequence(state.data.fileType, extension, a.name);
        const sb = getSequence(state.data.fileType, extension, b.name);
        if (sa < sb) return -1;
        if (sa > sb) return 1;
        return 0;
      });


      return {
        ...state,
        files: newFileList
      }
    }

    case BATCH_REMOVE_FILE:{
      const newFileList = [];
      for (const file of state.files){
        if (file.name !== action.value.name){
          newFileList.push(file);
        }
      }

      return {
        ...state,
        files: newFileList
      }
    }


    case BATCH_ADD_MODEL_FILE:{
      const file = {
        model:true,
        name: action.value.name,
        status:'QUEUED',
        bytesUploaded:0,
        md5:'',
        fileObject: action.value
      }

      let newFileList = state.modelFiles;
      newFileList.push(file);
      const extension = getExtension(state.data.fileType);
      newFileList = newFileList.sort((a, b)=>{
        const sa = getSequence(state.data.fileType, extension, a.name);
        const sb = getSequence(state.data.fileType, extension, b.name);
        if (sa < sb) return -1;
        if (sa > sb) return 1;
        return 0;
      });


      return {
        ...state,
        modelFiles: newFileList
      }
    }

    case BATCH_REMOVE_MODEL_FILE:{
      const newFileList = [];
      for (const file of state.modelFiles){
        if (file.name !== action.value.name){
          newFileList.push(file);
        }
      }

      return {
        ...state,
        modelFiles: newFileList
      }
    }

    case BATCH_RESET:{
      return JSON.parse(JSON.stringify(initialHistory));
    }

    case BATCH_CREATE:{
      return {
        ...state,
        creating:true,
        createError:false,
        uploadCount:0,
        completedCount:0
      }
    }
    case BATCH_CREATE_ERROR:{
      return {
        ...state,
        creating:false,
        createError:true,
      }
    }
    case BATCH_CREATE_COMPLETE:{
      return {
        ...state,
        creating: false,
        data: action.value
      }
    }

    case BATCH_START_UPLOAD:{
      const fileList = state.files.concat(state.modelFiles);

      return {
        ...state,
        uploading:true,
        uploaded:false,
        uploadFileList:fileList
      }
    }

    case BATCH_HASH_FILE_START:{
      const newFileList = [];
      let uploadCount = state.uploadCount;
      for (const file of state.uploadFileList){
        if (file.name === action.value.name){
          file.status = 'HASHING';
          file.chunkStatus = 'IDLE';
          file.error = false;
          uploadCount++;
        }
        newFileList.push(file);
      }


      return {
        ...state,
        uploadFileList: newFileList,
        uploadCount: uploadCount
      }
    }

    case BATCH_UPLOAD_FILE_START:{
      const newFileList = [];
      for (const file of state.uploadFileList){
        if (file.name === action.value.name){
          file.status = 'UPLOADING';
          file.chunkStatus = 'IDLE';
          file.error = false;
        }
        newFileList.push(file);
      }

      return {
        ...state,
        uploadFileList: newFileList
      }
    }

    case BATCH_UPLOAD_CHUNK:{
      const newFileList = [];
      let uploadCount = state.uploadCount;
      for (const file of state.uploadFileList){
        if (file.name === action.name) {
          file.status = 'UPLOADING';
          file.chunkStatus = 'UPLOADING';
          file.error = false;
        }
        newFileList.push(file);
      }

      return {
        ...state,
        uploadFileList: newFileList,
        uploadCount: uploadCount
      }
    }

    case BATCH_UPLOAD_CHUNK_COMPLETE:{
      const newFileList = [];
      let uploadCount = state.uploadCount;
      let completedCount = state.completedCount;
      let uploadCompleting = false;
      for (const file of state.uploadFileList){
        if (file.name === action.name){

          if (action.bytesUploaded >= (file.fileObject.size)){
            //console.error("COMPLETE! " + action.bytesUploaded + ' ' + file.fileObject.size);
            file.status = 'COMPLETE';
            file.chunkStatus = 'COMPLETE';
            if (uploadCount > 0) uploadCount -=1 ;
            completedCount+=1;

            if (completedCount >= state.uploadFileList.length){
              uploadCompleting = true;
            }
          } else {
            file.status = 'UPLOADING';
            file.chunkStatus = 'IDLE';
          }
          file.bytesUploaded = action.bytesUploaded;
          file.error = false;
        }
        newFileList.push(file);
      }

      return {
        ...state,
        uploadFileList: newFileList,
        uploadCount: uploadCount,
        completedCount: completedCount,
        uploadCompleting: uploadCompleting,
        uploadCompleted: false
      }
    }

    case BATCH_UPLOAD_CHUNK_ERROR:{
      const newFileList = [];
      for (const file of state.uploadFileList){
        if (file.name === action.name){
          file.status = 'UPLOADING';
          file.chunkStatus = 'IDLE';
          file.error = true;
        }
        newFileList.push(file);
      }

      return {
        ...state,
        uploadFileList: newFileList

      }
    }

    case BATCH_UPLOAD_COMPLETE:{
      return {
        ...state,
        uploadCount:0,
        uploaded: true,
        uploading: false,
        uploadCompleting: false,
        uploadCompleted: true

      }
    }

    case BATCH_CHECK_RELOAD:{
      return {
        ...state,
        checkingReload: true
      }
    }

    case BATCH_CHECK_RELOAD_COMPLETE:{
      return {
        ...state,
        checkingReload: false
      }
    }

    default:
      return state
  }


}


import {
  USER_UPDATE, USER_UPDATE_COMPLETE,

  USERS_LOAD_COMPLETE, USERS_LOAD_ERROR,
  USERS_LOAD_REQUESTED, USERS_RESET
} from "../actionTypes";
import {SERVER_URL} from "../config";
import {authRefreshTokenIfExpired} from "./auth";

export const usersReset = ()  => async (dispatch) => {
  await dispatch({type:USERS_RESET});
}

export const userAdd = (email, phoneNumber)  => async (dispatch, getState) => {
  await dispatch(authRefreshTokenIfExpired());
  const user = {
    email:email,
    phoneNumber: phoneNumber
  }

  try {
    dispatch({type: USER_UPDATE});
    const url = SERVER_URL + '/api/users';
    const response = await fetch(url, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + getState().auth.accessCode,
      },
      body: JSON.stringify(user)
    });

    if (response.status === 200) {
      const users = await response.json();
      console.log('[usersLoad] Load Users successful!');
      await dispatch({
        type: USER_UPDATE_COMPLETE,
      });
      await dispatch(usersLoad())
    } else {
      console.error('[userAdd] Server Returned error code: ' + response.status);
      await dispatch(usersLoad())
    }
  } catch (e) {
    console.error('[userAdd] Server exception: ',e);
    await dispatch(usersLoad())
  }
}


export const usersLoad = ()  => async (dispatch, getState) => {
  await dispatch(authRefreshTokenIfExpired());

  try {
    dispatch({type: USERS_LOAD_REQUESTED});
    const url = SERVER_URL + '/api/users';
    const response = await fetch(url, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + getState().auth.accessCode,
      },
    });

    if (response.status === 200) {
      const users = await response.json();
      console.log('[usersLoad] Load Users successful!');
      await dispatch({
        type: USERS_LOAD_COMPLETE,
        users: users
      });
    } else {
      console.error('[usersLoad] Server Returned error code: ' + response.status);
      await dispatch({
        type: USERS_LOAD_ERROR,
        users: []
      });
    }
  } catch (e) {
    console.error('[usersLoad] Server exception: ',e);
    await dispatch({
      type: USERS_LOAD_ERROR,
      users: []
    });
  }
}

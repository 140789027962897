import React from 'react';
import {StyleSheet, TextInput, View} from 'react-native'
import CountryCodeDropdown from "./CountryCodeDropDown";
import {COLOR_BLACK, COLOR_PRIMARY, FONT_FAMILY, FONT_SIZE_BODY} from "../../baseStyle";

const style = StyleSheet.create({
   container:{
       flex: 1,
       flexDirection: 'row',
       justifyContent: 'flex-start',
       alignContent:'center',
       alignItems: 'center',

       borderColor: COLOR_PRIMARY,
       borderStyle: 'solid',
       borderWidth:'1',
       backgroundColor:'#EAEAEA',
       borderRadius: 8,
       margin:8,
       padding:8,

   },
    textStyle:{
        fontFamily:FONT_FAMILY,
        fontSize:FONT_SIZE_BODY,
        color: COLOR_BLACK
    },
    textInputStyle: {
        marginLeft: 8,
        outlineStyle: 'none'
    }


});

export default function PhoneNumberInput(props) {
    //TODO: Split phone number into country code and base number.
    const countryCode = props.countryCode;
    const phoneNumber = props.phoneNumber;


    return (
        <View style={style.container}>
            <CountryCodeDropdown
                buttonStyle={{backgroundColor: 'transparent'}}
                titleStyle={style.textStyle}
                value={countryCode}
                onChange={(value)=>{
                    props.onCountryCodeChange(value)}}
            />

            <TextInput
                style={[style.textStyle, style.textInputStyle]}
                autoComplete='tel'
                autoFocus={true}
                maxLength={10}
                enablesReturnKeyAutomatically={true}
                onChangeText={(value) => {props.onPhoneNumberChange(value)}}
                placeholder="Phone Number"
                keyboardType="phone-pad"
                value={phoneNumber}
                autoCapitalize="none"
                textContentType="telephoneNumber"
                autoCorrect={false}

            />
        </View>

    );
}


import {Dimensions, StyleSheet} from 'react-native'
import {
    COLOR_GREY_DARK,
    COLOR_GREY_LIGHTER,
    COLOR_PRIMARY,
    COLOR_RED,
    COLOR_SECONDARY,
    FONT_FAMILY,
    FONT_SIZE_BODY,
    FONT_SIZE_CAPTION,
    FONT_SIZE_LARGE_HEADING
} from "../../baseStyle";

export default StyleSheet.create({
    logoImage:{
        height:127,
        width:371,
        margin:'auto',
        marginBottom:32,
        marginTop:64
    },
    inputContainer:{
        flexDirection:'column'
    },

    centeredBox:{
        width: Dimensions.get('window').width - 64,
        maxWidth: 600,
        margin:16,
        padding:16,
        backgroundColor:'#FFF',
        borderRadius:10,
        borderColor:'transparent',
        flexDirection:'column',
        shadowColor: '#000',
        shadowOffset: {width: -2, height: 4},
        shadowOpacity: 0.7,
        shadowRadius: 8,

    },
    appTitleText:{
        fontFamily:FONT_FAMILY,
        fontSize:FONT_SIZE_LARGE_HEADING,
        color: COLOR_PRIMARY,
        fontWeight:'100',
        textAlign:'center'
    },
    instructions:{
        fontFamily:FONT_FAMILY,
        fontSize:FONT_SIZE_CAPTION,
        color: '#000',
        margin:8,
    },

    orBox:{
        flexDirection:"row",
        justifyContent: 'center',
        alignContent:'center',
        alignItems: 'center',
        margin:16,
    },
    orText:{
        fontFamily:FONT_FAMILY,
        fontSize:FONT_SIZE_CAPTION,
        color:COLOR_GREY_DARK
    },
    orDash:{
        height:2,
        width:64,
        backgroundColor: COLOR_GREY_LIGHTER,
        marginLeft:16,
        marginRight:16,
    },
    switchModeButton:{
        margin:'auto',
        marginBottom:16,
        marginTop:16
    },
    switchModeText:{
        fontFamily:FONT_FAMILY,
        fontSize: FONT_SIZE_CAPTION,
        color: COLOR_SECONDARY
    },
    error:{
        color:COLOR_RED,
        fontSize: FONT_SIZE_BODY,
        fontFamily: FONT_FAMILY,
        textAlign:'center'
    },
    orButtonText: {
        fontSize: FONT_SIZE_CAPTION,
        textTransform: "none"
    },
})

import {
    AUTH_LOGIN_COMPLETE,
    AUTH_LOGIN_REQUESTED,
    AUTH_LOGOUT,
    AUTH_REFRESH_COMPLETE,
    AUTH_REFRESH_REQUESTED,
    AUTH_REQUEST_OTP,
    AUTH_RESET,
    AUTH_SET_EMAIL,
    AUTH_SET_LOGIN_TYPE,
    AUTH_SET_PHONE_NUMBER,
    AUTH_STATE_AUTHENTICATED,
    AUTH_STATE_FAILED,
    AUTH_USER_REQUEST,
    AUTH_USER_REQUEST_COMPLETE
} from "../actionTypes";
import {SERVER_URL} from "../config";
import {appLogout} from "./app";


export const authLogout = () => async (dispatch) => {
  console.log('[authLogout] Called');
  dispatch({ type: AUTH_LOGOUT });
}

export const authUseEmailForLogin = (value) => async (dispatch) => {
  dispatch({ type: AUTH_SET_LOGIN_TYPE, value:value });
}

export const authSetEmailAddress = (value) => async (dispatch) => {
  dispatch({ type: AUTH_SET_EMAIL, value:value });
}

export const authSetPhoneNumber = (countryCode, phoneNumber) => async (dispatch) => {
  dispatch({ type: AUTH_SET_PHONE_NUMBER, countryCode:countryCode, phoneNumber:phoneNumber });
}


export const authRequestOneTimePassword =(username) => async (dispatch) =>{
  try {
    const url = SERVER_URL + '/api/otp';
    const body = {username: username};

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    const result = await response.json();
    dispatch({type:AUTH_REQUEST_OTP})
  } catch(e){
    console.error('[authRequestOneTimePassword] Error requesting otp', e);
  }

}



export const authLogin = (username, code)  => async (dispatch) => {
  try {
    if (username.startsWith('+')) username = username.substring(1);
    console.log('[authLogin] attempting login for ' + username);
    const formContent = 'grant_type=password&client_id=web&scope=api'
        .concat('&username=').concat(username)
        .concat('&password=').concat(code);

    dispatch({type: AUTH_LOGIN_REQUESTED});
    const url = SERVER_URL + '/oauth/token';
    const response = await fetch(url, {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic d2ViOmdsaW1wc2VBUEk='
      },
      body: formContent
    });

    if (response.status === 200) {
      console.log('[authLogin] Authentication successful!')
      const data = await response.json();
      await dispatch({
        type: AUTH_LOGIN_COMPLETE,
        accessCode: data.access_token,
        refreshCode: data.refresh_token,
        expirationDate: (new Date()).getTime() + (data.expires_in * 1000),
        state: AUTH_STATE_AUTHENTICATED
      });
      dispatch(authLoadUser());
    } else {
      console.error('[authLogin] Server Returned error code: ' + response.status);
      await dispatch({
        type: AUTH_LOGIN_COMPLETE,
        accessCode: undefined,
        refreshCode: undefined,
        expirationDate: undefined,
        state: AUTH_STATE_FAILED
      });
    }
  } catch (e) {
    console.error('[authLogin] Error logging into server: ', e);
    dispatch({
      type: AUTH_LOGIN_COMPLETE,
      accessCode: undefined,
      refreshCode: undefined,
      expirationDate: undefined,
      state: AUTH_STATE_FAILED
    });
  }
}


  export const authRefreshToken = ()  => async (dispatch, getState) => {
    try {
      const auth = getState().auth;
      if (auth.refreshCode !== undefined && auth.refreshCode.length > 0) {
        console.log('[authRefreshToken] attempting refresh for ' + auth.refreshCode);
        const formContent = 'grant_type=refresh_token&client_id=web&scope=api&refresh_token='
            .concat(auth.refreshCode);
        await dispatch({type: AUTH_REFRESH_REQUESTED});


        const url = SERVER_URL + '/oauth/token';
        const response = await fetch(url, {
          method: 'post',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Basic d2ViOmdsaW1wc2VBUEk='
          },
          body: formContent
        });

        if (response.status === 200) {
          console.log('[authRefreshToken] Refresh successful')
          const data = await response.json();

          //Check to see if a new refresh token was issued
          let refresh_token = auth.refreshCode;
          if (data.refresh_token !== undefined && data.refresh_token !== null && data.refresh_token.length > 0) {
            refresh_token = data.refresh_token;
          }
          await dispatch({
            type: AUTH_REFRESH_COMPLETE,
            accessCode: data.access_token,
            refreshCode: refresh_token,
            expirationDate: (new Date()).getTime() + (data.expires_in * 1000),
          });
          dispatch(authLoadUser())
        } else {
          console.error('[authRefreshToken] Server Returned error code: ' + response.status);
          dispatch(authLogout());
        }
      }
    } catch (e) {
      console.error('[authRefreshToken] Error refreshing token. Forcing logout', e);
      await dispatch(authLogout());
    }

  }

  //Call this before any server functions are executed
  export const authRefreshTokenIfExpired = ()  => async (dispatch, getState) =>{
      const expirationDate = getState().auth.expirationDate;
      const currentTime = (new Date().getTime() - 60000); //Give it a minute window in case of clocks being out of sync
      if (!getState().auth.isRefreshing && (currentTime >= expirationDate)){
        console.log('[authRefreshTokenIfExpired] Token Expired. Refreshing');
        await dispatch(authRefreshToken());
      } else {
        if ((getState().auth.user === undefined)  && (!getState().auth.isUserLoading)){
          await dispatch(authLoadUser())
        }
      }
      await dispatch(authWaitForRefresh());
  }


export const authReset = () =>async(dispatch)=>{
  await dispatch({type: AUTH_RESET});
}


const _authWaitForRefresh = (getState, count, res=null) => {

  if (count > 100){
    res(false);
  } else if (res != null && !getState().auth.isRefreshing){
    res(true);
  } else {
    return new Promise((r)=>{
      setTimeout(()=>{
        _authWaitForRefresh(getState, count+1, r);
      }, 100);
    });
  }
}

export const authWaitForRefresh = () => async(dispatch, getState) => {
  if (getState().auth.isRefreshing) {
    const success = await _authWaitForRefresh(getState, 0);
    if (!success) {
      dispatch(authLogout());
    }
  }
}



export const authLoadUser = ()  => async (dispatch, getState) => {
  try {
    dispatch({type: AUTH_USER_REQUEST});
    const url = SERVER_URL + '/api/user';
    const response = await fetch(url, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + getState().auth.accessCode,
      },
    });

    if (response.status === 200) {
      const data = await response.json();
      console.log('[authLoadUser] Load User successful!', data)
      await dispatch({
        type: AUTH_USER_REQUEST_COMPLETE,
        user: data
      });
    } else {
      console.error('[authLoadUser] Server Returned error code: ' + response.status);
      await dispatch(appLogout());
    }
  } catch (e) {
    console.error('[authLoadUser] Error logging into server: ', e);
    await dispatch(appLogout());
  }
}

import AsyncStorage from "@react-native-async-storage/async-storage";
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import {getEnvVars} from "../environment";

export const STORAGE_KEY = 'GlimpseWeb.20220204_1600';

export const SERVER_URL = getEnvVars().serverUrl;
export const WEBSOCKET_URL = getEnvVars().websocketUrl;


export const persistConfig = {
  key: STORAGE_KEY,
  storage: AsyncStorage,
  stateReconciler: autoMergeLevel2,
  transforms: []
}



import {persistCombineReducers, purgeStoredState} from 'redux-persist'
import * as reducers from './reducers'

import {persistConfig, STORAGE_KEY} from "./config";
import AsyncStorage from "@react-native-async-storage/async-storage";


const appReducers = persistCombineReducers(persistConfig, reducers)

export const clearPersistedState = () => {
  purgeStoredState(persistConfig);
  AsyncStorage.removeItem(STORAGE_KEY);
}

const rootReducer = (state, action) => {
  //May change these depending on device
  if (action.type === 'RESET') {
    console.log('[store][reducers][rootReducer] Clearing app state')
    state = {}
    clearPersistedState()
  }
  return appReducers(state, action)
}

export default rootReducer

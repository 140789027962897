import {Dimensions, Platform, StyleSheet} from 'react-native'
import {responsiveFontSize} from 'react-native-responsive-dimensions'


// Note: The 0 values for RBG give black.
// Alpha is essentially opacity, so 1.0 alpha is
// fully opaque black
const greyGen = (alpha) => `rgba(25, 23, 60, ${alpha})`


export const COLOR_PRIMARY = '#004D90'
export const COLOR_PRIMARY_LIGHTER = '#7fa7f4'
export const COLOR_PRIMARY_LIGHT = '#4B78C1'
export const COLOR_PRIMARY_DARK = '#002662'

export const COLOR_SECONDARY = '#004d40'
export const COLOR_SECONDARY_LIGHT = '#39796b'
export const COLOR_SECONDARY_DARK = '#00251a'

export const COLOR_BLACK = greyGen(0.9)
export const COLOR_GREY_DARKER = greyGen(0.8)
export const COLOR_GREY_DARK = greyGen(0.6)
export const COLOR_GREY_LIGHT = greyGen(0.3)
export const COLOR_GREY_LIGHTER = greyGen(0.2)
export const COLOR_GREY_LIGHTER_OPAQUE = '#C4C4C4' // Temp wheel
export const COLOR_GREY_LIGHTEST = '#F4F4F4'
export const COLOR_WHITE = '#FFFFFF'
export const COLOR_BANNER = 'rgba(211,219,225,1)'
export const COLOR_RED = '#FF0000'
export const COLOR_ORANGE = '#FFB200'
export const COLOR_BLUE = '#2199E8'
export const COLOR_GREEN = '#3ADB76'
export const COLOR_TRANSPARENT = 'rgba(0,0,0,0)'
export const COLOR_TEXT_FIELD_BACKGROUND = '#F6F6F6';
export const COLOR_TEXT_FIELD_PLACEHOLDER = '#727272';
export const COLOR_WARNING_YELLOW = '#F1CE78'

// These should be considered the main font sizes and are
// based on designs on an iPhone 7 form factor. Other
// sizes may exist, but they are selected for individual
// use cases

export const isWeb = Platform.OS === 'web'
export const isIOS = Platform.OS === 'ios'
export const isAndroid = Platform.OS === 'android'


export const FONT_SIZE_CAPTION = responsiveFontSize(isWeb?1.56824/2:1.56824)
export const FONT_SIZE_BODY = responsiveFontSize(isWeb?2.09099/2:2.09099)
// export const FONT_SIZE_FORM = responsiveFontSize(2.35236)
export const FONT_SIZE_FORM = responsiveFontSize(isWeb?2.09099/2:2.09099)
export const FONT_SIZE_HEADING = responsiveFontSize(isWeb?2.87511/2:2.87511)
export const FONT_SIZE_CARD_HEADING = responsiveFontSize(isWeb?3.13648/2:3.13648)
export const FONT_SIZE_CAROUSEL_HEADING = responsiveFontSize(isWeb?2.35236/2:2.35236)
export const FONT_SIZE_LARGE_HEADING = responsiveFontSize(isWeb?4.18197/2:4.18197)


export const FONT_FAMILY =  isWeb?'sans-serif':(isIOS?'ArialMT':'sans-serif-light');
export const FONT_FAMILY_THIN =  isWeb?'sans-serif':(isIOS?'ArialMT':'sans-serif-thin');
export const FONT_FAMILY_MONO = isWeb?'monospace':(isIOS ? 'Courier' : 'monospace');


const baseStyle = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
    },
    containerScrollView:{
        marginHorizontal: 20,
    },

    headerIcon: {
        paddingLeft:16,
        paddingRight:16
    },

    caption: {
        fontSize:FONT_SIZE_CAPTION,
        fontFamily:FONT_FAMILY,
        textTransform:"uppercase",
        color:COLOR_GREY_DARK,
        padding:8
    },
        raisedButton:{
            backgroundColor: COLOR_SECONDARY,
            borderRadius:8,
            shadowColor: '#000',
            shadowOffset: {width: -2, height: 4},
            shadowOpacity: 0.3,
            shadowRadius: 4,
            marginRight:16,
            marginLeft:16

        },
    raisedButtonText:{
        fontSize:FONT_SIZE_BODY,
        fontFamily:FONT_FAMILY,
        textTransform:"uppercase",
        color:COLOR_WHITE,
        padding:8,
        textAlign:'center'
    },
    raisedCard: {
        backgroundColor:COLOR_WHITE,
        shadowColor: '#000',
        shadowOffset: {width: -2, height: 4},
        shadowOpacity: 0.3,
        shadowRadius: 4,
        borderRadius:8,
        flexDirection:'column',
        justifyContent:'space-between',
        padding:24,
        width:Dimensions.get('window').width - 64,
        maxWidth:1024,
        margin:24
    },
    titleStyle:{
        fontSize:FONT_SIZE_HEADING,
        fontFamily:FONT_FAMILY,
        color:COLOR_PRIMARY_LIGHT,
        padding:8,
        textAlign:'center'
    },
    subheadStyle:{
        fontSize:FONT_SIZE_CAPTION,
        fontFamily:FONT_FAMILY,
        color:COLOR_PRIMARY_LIGHT,
        textTransform:"uppercase",
        fontWeight:"bolder",
        padding:8,
        textAlign:'center'
    },
    subcaptionContainerStyle: {
        flexDirection:'column',
        justifyContent:'flex-start'
    },
    subcaptionText: {
        fontSize:12,
        fontFamily:FONT_FAMILY,
        color:COLOR_GREY_DARK,
        padding:0,
        margin:0,
        marginLeft:12,
        maxWidth:300,
        textAlign:"left"
    },


    clearButtonText:{
        fontSize:FONT_SIZE_BODY,
        fontFamily:FONT_FAMILY,
        textTransform:"uppercase",
        color:COLOR_SECONDARY,
        padding:8,
        textAlign:'center'
    },
    instructions: {
        fontSize:FONT_SIZE_CAPTION,
        fontFamily:FONT_FAMILY,
        color:COLOR_GREY_DARK,
        padding:8,
        textAlign:'center'
    },
    buttonContainer: {
        flexDirection:'row',
        justifyContent:'flex-end',
        alignItems:'center'
    },
    dialogText: {
        fontSize:FONT_SIZE_BODY,
        fontFamily:FONT_FAMILY,
        color:COLOR_BLACK,
    },
    columnHeaderRow: {
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'flex-end',
        paddingLeft:16,
        marginLeft:6, //2 extra for border,
        marginRight:16,
        marginTop:8,
        alignSelf: 'stretch'

    },
    columnHeaderText:{
        fontSize:FONT_SIZE_CAPTION * 0.60,
        fontFamily:FONT_FAMILY,
        color:COLOR_BLACK,
        opacity:0.7,
        textTransform:"uppercase",
        flex:1,
        padding:16,
        paddingBottom:0,
        paddingTop:0
    },
    batchListScrollView:{
        flexDirection:'column',
        flex:1,
        alignSelf: 'stretch'
    },


    textFieldStyle:{
        backgroundColor:COLOR_TEXT_FIELD_BACKGROUND,
        color: COLOR_BLACK,
        margin:32, marginTop:8, marginBottom:0
    }




})

export default baseStyle

export const BATCHES_LOAD_REQUESTED = 'BATCHES_LOAD_REQUESTED';
export const BATCHES_LOAD_COMPLETE = 'BATCHES_LOAD_COMPLETE';
export const BATCHES_LOAD_ERROR = 'BATCHES_LOAD_ERROR';
export const BATCH_RESET = 'BATCH_RESET';
export const BATCH_ADD_FILE = 'BATCH_ADD_FILE';
export const BATCH_REMOVE_FILE = 'BATCH_REMOVE_FILE';

export const BATCH_ADD_MODEL_FILE = 'BATCH_ADD_MODEL_FILE';
export const BATCH_REMOVE_MODEL_FILE = 'BATCH_REMOVE_MODEL_FILE';


export const BATCH_UPDATE = 'BATCH_UPDATE';


export const BATCH_CREATE = 'BATCH_CREATE';
export const BATCH_CREATE_ERROR = 'BATCH_CREATE_ERROR';
export const BATCH_CREATE_COMPLETE = 'BATCH_CREATE_COMPLETE';


export const BATCH_REPEAT = 'BATCH_REPEAT';

export const BATCH_START_UPLOAD = 'BATCH_START_UPLOAD';
export const BATCH_UPLOAD_FILE_START = 'BATCH_UPLOAD_FILE_START';
export const BATCH_HASH_FILE_START = 'BATCH_HASH_FILE_START';
export const BATCH_HASH_FILE_COMPLETE = 'BATCH_HASH_FILE_COMPLETE';
export const BATCH_UPLOAD_CHUNK = 'BATCH_UPLOAD_CHUNK';
export const BATCH_UPLOAD_CHUNK_ERROR = 'BATCH_UPLOAD_CHUNK_ERROR';
export const BATCH_UPLOAD_CHUNK_COMPLETE = 'BATCH_UPLOAD_CHUNK_COMPLETE';
export const BATCH_UPLOAD_COMPLETE = 'BATCH_UPLOAD_COMPLETE';


export const BATCH_CHECK_RELOAD = 'BATCH_CHECK_RELOAD';
export const BATCH_CHECK_RELOAD_COMPLETE = 'BATCH_CHECK_RELOAD_COMPLETE';
